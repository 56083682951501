import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"
import HtmlContent from "@src/components/core-value-html"

const Content = styled(HtmlContent)`
  font-family: system-ui;

  & > p:nth-child(3) {
    text-align: right;
  }
  & .item {
    display: flex;
    flex-direction: column;
    margin: 60px 0;
    & > a {
      display: contents;
      & > img {
        width: 160px;
        height: fit-content;
      }
    }
    & > .text {
      margin-top: 60px;
      padding-bottom: 24px;
      border-bottom: 1px solid lightgray;
      & a {
        text-decoration: underline;
        text-decoration-color: ${props => props.theme.primary};
        color: inherit;
        &:hover {
          color: ${props => props.theme.primary};
        }
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & .item {
      flex-direction: row;
      padding-bottom: 44px;
      border-bottom: 1px solid lightgray;
      & > a > img {
        margin-right: 24px;
      }
      & > .text {
        margin-top: 0;
        border-bottom: none;
        padding-bottom: none;
      }
    }
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    & .item > a > img {
      width: 260px;
    }
  }
`
const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        content,
        images: { header: headerImage },
        seo: pageSeo,
      },
    },
  } = data
  GTM.dataLayerPushPageInfo({
    template: "static-content",
  })
  // Split the content into 2 halves - one for quote and one for items
  let splitContent

  if (content && content.length) {
    const position = content.indexOf("<p> <a")
    if (position !== -1) {
      // Split the input string based on the position
      const firstPart = content.substring(0, position)
      const secondPart = content.substring(position)
      splitContent = [firstPart.trim(), secondPart.trim()]
    } else {
      splitContent = content.trim()
    }
  }
  // Function to re-arrange the second block into a stylable format
  const transformBlocks = inputString => {
    // Pattern provided by GPT-3
    const pattern =
      /<p>\s*<a[^>]+href="([^"]+)"[^>]+class="image-block"[^>]*><span[^>]*><img[^>]+width="\d+"[^>]+height="\d+"[^>]+src="([^"]+)"[^>]+alt="([^"]+)"[^>]*><\/span><\/a><\/p>\s*<p>(.*?)<\/p>\s*<p>(.*?)<\/p>/g
    let outputString = ""
    let match
    while ((match = pattern.exec(inputString)) !== null) {
      const [, hrefLink, srcLink, altText, textContent, discountText] = match
      const formattedBlock = `
      <div class="item">
        <a href="${hrefLink}">
          <img src="${srcLink}" alt="${altText}-asd" />
        </a>
        <div class="text">
          <p>${textContent}</p>
          <p>${discountText}</p>
        </div>
      </div>
    `
      outputString += formattedBlock
    }
    return outputString
  }
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, "index"],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{title}</h1>
      </HeaderSimple>
      <ResponsiveSection>
        <Content
          html={splitContent[0] + transformBlocks(splitContent[1])}
          tableStyle
          imageBlocks
        />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
